import React, { Fragment, useState, useRef } from "react";
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { API_URL } from "../../../../apiconfig";
import xlFile from "../files/Hospital.xlsx";
const UploadHospital = () => {
  const navigate = useNavigate();
  const fileInputRef = useRef(null);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [errorCount, setErrorCount] = useState("");
  const [errorFile, setErrorFile] = useState("");
  const [formData, setFormData] = useState({
    files: null,
  });

  const handleFileChange = (e) => {
    const files = e.target.files[0];
    console.log("files", files);
    if (files) {
      setFormData({ ...formData, files: files });
    }
    // if (files.type == ".xlsx" || files.type == ".xlsb" || files.type == ".xlsm" || files.type == ".xls") {
    //     setFormData({ ...formData, files: files });
    // } else {
    //     setError(`Please select valid files type`);
    //     setFormData({ ...formData, files: null });
    //     if (fileInputRef.current) {
    //         fileInputRef.current.value = null;
    //     }

    // }

    // console.log("files", files);
  };

  const showEmptyFieldWarning = () => {
    toast.info("❗ Please select an Excel files", {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    if (formData.files) {
      try {
        const config = {
          headers: {
            "content-type": "multipart/form-data",
          },
        };
        const formDataToSend = new FormData();
        formDataToSend.append("files", formData.files);

        const { data } = await axios.post(
          `${API_URL}/api/hospital/excel-hospital`,
          formDataToSend,
          config
        );
        console.log("Response data:", data);
        if (data && data.data.errorcount > 0) {
          toast.warning(
            `Some entries are missing,Fil the missing fields and re-upload`
          );
          setErrorCount(data && data.data.errorcount);
          setErrorFile(`${API_URL}/${data.data.ErrorData}`);
          fileInputRef.current.value = null;
          setLoading(false);
        } else {
          toast.success(`${data && data.message}`);
          setLoading(false);
          setFormData({ ...formData, files: null });
          if (fileInputRef.current) {
            fileInputRef.current.value = null;
          }
        }
        // if (data.status == 200) {
        //     setFormData({ ...formData, files: null });
        //     toast.success("✔️ Submission successful!", {
        //         position: "top-right",
        //         autoClose: 2500,
        //         hideProgressBar: false,
        //         closeOnClick: true,
        //         pauseOnHover: true,
        //         draggable: true,
        //         progress: undefined,
        //     });
        //     setLoading(true)

        // }
      } catch (error) {
        console.error("Error submitting HMO data:", error.message);
        setLoading(false);
      }
    } else {
      showEmptyFieldWarning();
    }
  };

  const handleReset = () => {
    setFormData({ ...formData, files: null });
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <Fragment>
      <AdminPageTitle
        activePage="Hospital Management"
        pageName="Upload Hospital Details"
      />

      {/* <div className="d-flex col-xl-10 row-lg-12"> */}
      <div className="card">
        <div className="card-header">
          <h4 className="card-title">Upload Hospital Details</h4>
        </div>

        <div className="card-body">
          <div className="basic-form">
            <form onSubmit={handleSubmit}>
              <div className="mb-3 row">
                <label htmlFor="excelFile" className="row-sm-3 col-form-label">
                  Upload Excel
                </label>
                <div className="col-sm-4">
                  <input
                    type="file"
                    name="excelFile"
                    className="form-control"
                    accept=".xlsx, .xls"
                    onChange={handleFileChange}
                    ref={fileInputRef}
                  />
                  {error && <div style={{ color: "red" }}>{error}</div>}
                </div>
              </div>

              <div style={{ display: "flex", gap: "20px" }}>
                <div className="row-sm-10">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    disabled={loading}
                  >
                    {loading ? "...submitting" : "Save"}
                  </button>
                </div>
                <div className="row-sm-10">
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={handleReset}
                  >
                    Reset
                  </button>
                </div>
                <div className="row-sm-10">
                  <a download href={xlFile}>
                    <img
                      src="https://naija.paperbirdtech.com/admin/image/excle-download.png"
                      alt=""
                    />
                  </a>
                </div>

                {errorCount && (
                  <div className="row-sm-10" style={{ marginLeft: "50%" }}>
                    <a download href={errorFile}>
                      {/* <img src="https://naija.paperbirdtech.com/admin/image/excle-download.png" alt="" /> */}
                      <button type="button" className="btn btn-danger">
                        Download Error Excel
                      </button>
                    </a>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
      {/* </div> */}
      <ToastContainer />
    </Fragment>
  );
};

export default UploadHospital;
