import React, { Fragment, useState, useRef, useEffect } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import default_logo from "../../../../image/default logo.png";
import { API_URL } from "../../../../apiconfig";
const AddPartner = () => {
    const navigate = useNavigate();
    const [image, setImage] = useState({ OurPartners_url: null });
    const [fileName, setFileName] = useState({ placeholder: "", file: null });
    const [loading, setLoading] = useState(false);
    const [photo, setPhoto] = useState('')
    const fileInputRef = useRef(null);
    const [Partnersname, setPartnerName] = useState('')
    useEffect(async () => {

    }, [])
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setPhoto(file.name);
            const reader = new FileReader();
            reader.onload = (r) => {
                setFileName({
                    placeholder: r.target.result,
                    file: e.target.files[0],
                });
            };
            reader.readAsDataURL(file);
        }
        setImage({ OurPartners_url: file });
    };



    const amptyField = (val) => {
        toast.info(`❗${val} Field is Empty`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setLoading(false)
    };
    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        if (Partnersname == "" || !Partnersname) {
            amptyField('Partner Name')
        } else if (photo == '' || !photo) {
            amptyField('Partner Logo')
        } else {
            try {

                if (image) {
                    const url = `${API_URL}/api/OurPartners/image`;
                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    };
                    axios.post(url, image, config).then((response) => {
                        // console.log('response',response);
                        toast.success(`Photo ${response.data}`)
                    });
                }

                setTimeout(async () => {
                    const { data } = await axios.post(`${API_URL}/api/OurPartners`, { Partnersname: Partnersname, ourPartners: photo });
                    // console.log(data, "data");

                    setLoading(false)
                    if (data.status == 400) {
                        toast.error(`❗ ${data.message}!`, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success("✔️ Submision successful !", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate('/admin/view/view_partners')
                    }


                }, 2500);
            } catch (error) {
                console.error("Error submitting HMO data:", error.message);
            }
        }


    };

    return (
        <Fragment>
            <AdminPageTitle activePage="Add Partner" pageName="AddPartner" />

            <div style={{ width: "75%" }} >
                <div className="card" >
                    <div className="card-header">
                        <h4 className="card-title">Add Partner</h4>
                    </div>

                    <div className="card-body">
                        <div className="basic-form">


                            <div className="mb-3 row">
                                <label htmlFor="photo" className="col-sm-3 col-form-label">Partner Name</label>
                                <div className="col-sm-9">
                                    <input
                                        type="text"
                                        accept=".jpeg, .png"
                                        className="form-control"
                                        onChange={(e) => e && setPartnerName(e.target.value)}

                                    />
                                </div>
                            </div>

                            <div className="mb-3 row">
                                <label htmlFor="photo" className="col-sm-3 col-form-label">Upload Partner Logo</label>
                                <div className="col-sm-9">
                                    <input
                                        type="file"
                                        id="photo"
                                        name="photo"
                                        accept=".jpeg, .png"
                                        className="form-control"
                                        onChange={handleFileChange}
                                        ref={fileInputRef}
                                    />
                                </div>
                            </div>
                            <div className="mb-3 justify-content-center">
                                <label htmlFor="photo" className="col-sm-3 col-form-label">Logo preview</label>
                                <img className="ml-4" style={{ objectFit: "cover", border: "5px solid gray", marginLeft: "100px", padding: "5px" }} src={fileName.placeholder ? fileName.placeholder : default_logo} alt="" height={200} width={200} />

                            </div>


                            <div className="mb-3 row">
                                <div className="col-sm-10">
                                    {loading ? <button type="loading" className="btn btn-primary" disabled>
                                        Submitting
                                    </button> :
                                        <button onClick={handleSubmit} className="btn btn-primary">
                                            Submit
                                        </button>}
                                </div>
                            </div>


                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer />
        </Fragment>
    );
};

export default AddPartner;
