import React, { Fragment, useEffect, useRef, useState } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { API_URL } from "../../../../apiconfig";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";

let styles = { cursor: "not-allowed", background: "#DDDDDD" };
const EditCorporatePlan = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    local_govt: "",
    contact_person: "",
    no_of_staff: "",
    budget: "",
    state: "",
  });
  useEffect(async () => {
    const { data } = await axios.get(
      `${API_URL}/api/indivual_plan_master/${id}`
    );
    const {
      name,
      email,
      phone,
      paymentMethod,
      amount,
      transactionId,
      expiryDate,
      state,
    } = data;
    setFormData({
      ...formData,
      name: name,
      email: email,
      phone: phone,
      paymentMethod: paymentMethod,
      amount: amount,
      transactionId: transactionId,
      expiryDate: expiryDate,
    });
    // setState(data)
    // console.log(data);
  }, []);

  const handleRedirect = async (e) => {
    navigate("/admin/view/view_corporate_plan");
  };

  return (
    <Fragment>
      <AdminPageTitle
        activePage="Manage orders"
        pageName="View Corporate Plan"
      />

      <div className="col-xl-12 row-lg-12">
        <div className="card">
          <div className="card-header">
            <h4 className="card-title">View Corporate Plan</h4>
          </div>

          <div className="card-body">
            <div className="basic-form">
              <form onSubmit={handleRedirect}>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Name</label>
                    <div className="col-sm-9">
                      <input
                        name="name"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.name}
                        style={styles}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Email</label>
                    <div className="col-sm-9">
                      <input
                        name="email"
                        type="text"
                        className="form-control"
                        placeholder="enter valid email"
                        value={formData.email}
                        disabled
                        style={styles}
                      />
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">
                      Contact No
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        id="phone"
                        name="phone"
                        className="form-control"
                        placeholder="Contact No"
                        value={formData.phone}
                        style={styles}
                        disabled
                        // onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">Amount (₦)</label>
                    <div className="col-sm-9">
                      <input
                        name="amount"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.amount}
                        style={styles}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">
                      Transaction Id
                    </label>
                    <div className="col-sm-9">
                      <input
                        name="transactionId"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.transactionId}
                        style={styles}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="row-sm-3 col-form-label">
                      Payment Method
                    </label>
                    <div className="col-sm-9">
                      <input
                        name="paymentMethod"
                        type="text"
                        disabled
                        className="form-control"
                        // placeholder="enter hospital name"
                        value={formData.paymentMethod}
                        // onChange={handleChange}
                        style={styles}
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">
                      Expiry Date
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="expiryDate"
                        className="form-control"
                        value={moment(formData.expiryDate).format("YYYY-MM-DD")}
                        disabled
                        style={styles}
                      />
                    </div>
                  </div>
                  <div className="form-group mb-3 col-md-6">
                    <label className="col-sm-3 col-form-label">
                      Contact Person
                    </label>
                    <div className="col-sm-9">
                      <input
                        type="text"
                        name="phone"
                        className="form-control"
                        value={formData.phone}
                        disabled
                        style={styles}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-sm-10">
                  <button type="submit" className="btn btn-primary">
                    Close
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default EditCorporatePlan;
