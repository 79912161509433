import React, { Fragment, useState, useRef, useEffect } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import default_logo from "../../../../image/default logo.png";
import { API_URL } from "../../../../apiconfig";
const EditDoctor = () => {
  const { id } = useParams()
  const navigate = useNavigate();
  const [videoFile, setVideoFile] = useState({ fileuploads: null });
  const [image, setImage] = useState({ photouploads: null });
  const [fileName, setFileName] = useState({ placeholder: "", file: null });
  const [imagePreview, setImagePreview] = useState('');
  const [imageData, setImageData] = useState('');
  const [formData, setFormData] = useState({
    doctor_name: "",
    email: "",
    phone: "",
    specialty: "",
    address: "",
    photo: null,
    document: null,
    state: "",
    experience: "",
    local_govt: "",
  });
  const [phoneNumber, setPhoneNumber] = useState("");
  const [error, setError] = useState("");
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [loading, setLoading] = useState(false);
  const fileInputRef = useRef(null);
  const getDoctorsData = async () => {
    const { data } = await axios.get(`${API_URL}/api/doctorDetails/${id}`)
    // console.log('data', data);
    const { address, doctor_name, document, email, experience, local_govt, phone, photo, specialty, state } = data.data
    setFormData({
      ...formData,
      doctor_name: doctor_name,
      email: email,
      phone: phone,
      specialty: specialty,
      address: address,
      photo: photo,
      document: document,
      state: state,
      experience: experience,
      local_govt: local_govt
    })
    setImageData(photo)
  }
  useEffect(async () => {
    const { data } = await axios.get(`${API_URL}/api/hospital/state`)
    setState(data.data)
    getDoctorsData()
  }, [])
  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFormData({
        ...formData,
        photo: file.name,
      });

      const reader = new FileReader();
      reader.onload = (r) => {
        setFileName({
          placeholder: r.target.result,
          file: file,
        });
        setImagePreview(URL.createObjectURL(file)); // Set the preview URL
      };
      reader.readAsDataURL(file);
      setImage({ photouploads: file });
    }
  };

  const handleStateChange = async (e) => {
    const { stateValue, value } = e.target
    if (value) {
      let selectedState = state.filter((el) => { return el.id == value })
      setFormData({ ...formData, state: selectedState[0].state })
    }
    const { data } = await axios.get(`${API_URL}/api/hospital/city/${value}`)
    setCity(data.data)
  }
  const handleVideoFileChange = (e) => {
    const file = e.target.files[0];
    // console.log("file",file);
    
    if (file) {
      setVideoFile({fileuploads:file});
      setFormData(prev => ({ ...prev, document: file.name }));
    }
  };
  
  const handlePhoneChange = (e) => {
    const value = e.target.value;


    if (/[^0-9]/.test(value)) {
      setError('Please enter valid number');
    } else {
      setError('');
    }
    setPhoneNumber(value.replace(/\D/g, ''));
    setFormData({ ...formData, phone: value.replace(/\D/g, '') })
  };
  const handleBlur = () => {
    if (phoneNumber.length > 0 && phoneNumber.length !== 10) {
      setError('Phone number must be 10 digits.');
    }
  };
  const amptyField = (val) => {
    toast.info(`❗${val} Field is Empty`, {
      position: "top-right",
      autoClose: 2500,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
    setLoading(false)
  };
  const handleChange = (e) => {
    const { name, value } = e.target;

    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true)
    const data = new FormData();
    for (const key in formData) {
      data.append(key, formData[key]);
    }
    if (formData.doctor_name == "") {
      amptyField("Doctor Name");

    } else if (formData.email == "") {
      amptyField("Email");
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,6}$/i.test(formData.email)
    ) {
      toast.info("❗ Enter valid email", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false)
    } else if (
      formData.phone == "" ||
      !formData.phone
    ) {
      toast.info("❗ Add Valid Mobile Number", {
        position: "top-right",
        autoClose: 2500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setLoading(false)
    } else if (

      formData.address == "" ||
      formData.photo == ""
    ) {
      amptyField();
    } else {
      setLoading(true)
      try {

        if (image) {
          const url = `${API_URL}/api/doctorDetails/photo`;
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          axios.post(url, image, config).then((response) => {
            // console.log('response',response);
            toast.success(`Photo ${response.data}`)
          });
        }

        if (videoFile) {
          const url = `${API_URL}/api/doctorDetails/upload`;
          const config = {
            headers: {
              "content-type": "multipart/form-data",
            },
          };
          axios.post(url, videoFile, config).then((response) => {
            // console.log('response',response);
            toast.success(`Document ${response.data}`)

          });
        }
        setTimeout(async () => {
          const { data } = await axios.put(`${API_URL}/api/doctorDetails/${id}`, formData);
          // console.log(data, "data");

          setLoading(false)
          if (data.status == 400) {
            toast.error(`❗ ${data.message}!`, {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
            toast.success("✔️ Submision successful !", {
              position: "top-right",
              autoClose: 2500,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
            navigate("/admin/view/view_doctors");
          }


        }, 2500);
      } catch (error) {
        console.error("Error submitting HMO data:", error.message);
      }
    }
  };

  return (
    <Fragment>
      <AdminPageTitle activePage="Manage Doctors" pageName="EditDoctor" />

      <div style={{ width: "75%" }} >
        <div className="card" >
          <div className="card-header">
            <h4 className="card-title">Edit Doctor</h4>
          </div>

          <div className="card-body">
            <div className="basic-form">

              <form onSubmit={handleSubmit}>

                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Doctor's Name</label>
                  <div className="col-sm-9">
                    <input
                      name="doctor_name"
                      type="text"
                      className="form-control"
                      placeholder="Doctor's Name"
                      value={formData.doctor_name}
                      onChange={handleChange}


                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Email</label>
                  <div className="col-sm-9">
                    <input
                      name="email"
                      type="text"
                      className="form-control"
                      placeholder="Email"
                      value={formData.email}
                      onChange={handleChange}

                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Contact No</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      id="phone"
                      name="phone"
                      className="form-control"
                      placeholder="Enter your phone number"
                      value={formData.phone}
                      onChange={handlePhoneChange}

                      maxLength={10}
                      minLength={10}
                      onBlur={handleBlur}

                    />
                    {error && <div style={{ color: 'red' }}>{error}</div>}
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Specialist</label>
                  <div className="col-sm-9">
                    <select
                      className="form-control"
                      name="specialty"
                      onChange={handleChange}
                      value={formData.specialty}
                      required
                    >
                      <option value={""}>Specialist</option>
                      <option value={"Gynecologist"}>Gynecologist</option>
                      <option value={"Dentist"}>Dentist</option>
                      <option value={"Physiotherapist"}>Physiotherapist</option>
                      <option value={"surgeon"}>surgeon</option>
                      <option value={"Nephrologist"}>Nephrologist</option>
                      <option value={"Ophthalmologist"}>Ophthalmologist</option>

                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Experience</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      id="experience"
                      name="experience"
                      className="form-control"
                      placeholder="Experience"
                      value={formData.experience}
                      onChange={handleChange}

                      maxLength={2}
                      minLength={1}
                      onBlur={handleBlur}

                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">State</label>
                  <div className="col-sm-9">
                    <select
                      //   defaultValue={formData.type_of_hospital}
                      className="form-control form-control"
                      name="state"
                      //   value={formData.state}
                      onChange={(e) => handleStateChange(e)}
                      //   onSelect={(e)=>handleStateValue(e)}
                      required
                    >
                      {formData.state ? <option>{formData.state}</option> : <option>Select</option>}
                      {state && state.map((el) => {
                        return <option value={el.id} key={el.id}  >{el.state}</option>
                      })}
                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Local Goverment</label>
                  <div className="col-sm-9">
                    <select
                      //   defaultValue={'Select local govt type'}
                      className="form-control form-control"
                      name="local_govt"
                      value={formData.local_govt}
                      onChange={handleChange}

                    >
                      <option >{formData.local_govt ? formData.local_govt : "Select local goverment"}</option>

                      {city ? city.map((el, i) => {
                        return (
                          <option value={el.city} key={i}>{el.city}</option>
                        )
                      }) : <option value={""}>Select local goverment</option>
                      }
                      {/* <option value={"Select"}>Select local govt type</option> */}

                    </select>
                  </div>
                </div>
                <div className="mb-3 row">
                  <label className="col-sm-3 col-form-label">Address</label>
                  <div className="col-sm-9">
                    <input
                      type="text"
                      name="address"
                      className="form-control"
                      placeholder="Address"
                      value={formData.address}
                      onChange={handleChange}

                    />
                  </div>
                </div>
                <div className="mb-3 row">
                  <label htmlFor="photo" className="col-sm-3 col-form-label">Upload Photo</label>
                  <div className="col-sm-9">
                    {/* <input
											className="form-control"
											type="file"
											id="photo"
											name="photo"
											onChange={handleFileChange}
										/> */}
                    <input
                      type="file"
                      id="photo"
                      name="photo"
                      accept=".jpeg, .png"
                      className="form-control"
                      onChange={handleFileChange}
                      ref={fileInputRef}
                    />
                  </div>
                </div>
                <div className="mb-3 justify-content-center">
                  <label htmlFor="photo" className="col-sm-3 col-form-label">Photo preview</label>
                  <img className="ml-4" style={{ objectFit: "cover", border: "5px solid gray", marginLeft: "100px", padding: "5px" }} src={imagePreview ?imagePreview : `${API_URL}/public/uploads/doctorDetails/` + imageData } alt="" height={200} width={200} />

                </div>

                <div className="mb-3 row">
                  <label htmlFor="document" className="col-sm-3 col-form-label">Upload Document</label>
                  <div className="col-sm-9">
                    <input
                      className="form-control"
                      type="file"
                      name="document"
                      accept=".pdf, jpeg, jpg"
                      onChange={handleVideoFileChange}
                    />
                    <div> {formData.document && formData.document} </div>
                  </div>
                </div>

                <div className="mb-3 row">
                  <div className="col-sm-10">
                    {loading ? <button type="loading" className="btn btn-primary" disabled>
                      Submitting
                    </button> :
                      <button type="submit" className="btn btn-primary">
                        Submit
                      </button>}
                  </div>
                </div>
              </form>

            </div>
          </div>
        </div>


      </div>
      <ToastContainer />
    </Fragment>
  );
};

export default EditDoctor;
