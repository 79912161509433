import React, { Fragment, useState, useRef, useEffect } from "react";
// import styles
import "lightgallery/css/lightgallery.css";
import "lightgallery/css/lg-zoom.css";
import "lightgallery/css/lg-thumbnail.css";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import default_logo from "../../../../image/event.jpg";
import { API_URL } from "../../../../apiconfig";
const EditEvents = () => {
    const { id } = useParams();

    const navigate = useNavigate();
    const [videoFile, setVideoFile] = useState({ video_url: null });
    const [image, setImage] = useState({ event_url: null });
    const [fileName, setFileName] = useState({ placeholder: "", file: null });
    const [formData, setFormData] = useState({
        description: "",
        logo_url: "",
    });
    const [error, setError] = useState("");
    const [imageError, setImageError] = useState("");
    const [loading, setLoading] = useState(false);
    const fileInputRef = useRef(null);
    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get(`${API_URL}/api/event/${id}`)
            const { description, logo_url } = data.data;

            setFormData({
                ...formData,
                description: description,
                logo_url: logo_url
            })

        }
        fetchData()
        fetchData()
    }, [])
    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            if (file.type === "image/png" || file.type === "image/jpeg") {
                setFormData({
                    ...formData,
                    logo_url: file.name,
                });
                const reader = new FileReader();
                reader.onload = (r) => {
                    setFileName({
                        placeholder: r.target.result,
                        file: e.target.files[0],
                    });
                };
                reader.readAsDataURL(file);
                setImageError("");
            } else {
                setImageError("Invalid image format. Only PNG and JPEG are allowed.");
                // Reset the file input value
                e.target.value = "";
            }
        }
        setImage({ event_url: file });
    };
    // const handleFileChange = (e) => {
    //     const file = e.target.files[0];
    //     if (file) {
    //         if (file.type === "image/png" || file.type === "image/jpeg") {
    //             setFormData({
    //                 ...formData,
    //                 logo_url: file.name,
    //             });
    //             const reader = new FileReader();
    //             reader.onload = (r) => {
    //                 setImage({
    //                     event_url: e.target.files[0], // Update image.event_url with base64 encoded string
                     
    //                 });
    //             };
    //             reader.readAsDataURL(file);
    //             setImageError("");
    //         } else {
    //             setImageError("Invalid image format. Only PNG and JPEG are allowed.");
    //             // Reset the file input value
    //             e.target.value = "";
    //         }
    //     }
    // };
    const amptyField = (val) => {
        toast.info(`❗${val} Field is Empty`, {
            position: "top-right",
            autoClose: 2500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
        });
        setLoading(false)
    };
    const handleChange = (e) => {
        const { name, value } = e.target;

        setFormData({
            ...formData,
            [name]: value,
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true)
        console.log("formdata", formData);

        const data = new FormData();
        for (const key in formData) {
            data.append(key, formData[key]);
        }
        if (formData.image == "") {
            amptyField("Image");

        } else if (
            formData.description == ""
        ){
            amptyField("Description");
        } else {
            setLoading(true)
            try {

                if (image) {
                    const url = `${API_URL}/api/event/image`;
                    const config = {
                        headers: {
                            "content-type": "multipart/form-data",
                        },
                    };
                    axios.post(url, image, config).then((response) => {
                       
                    });
                }
                setTimeout(async () => {
                    const { data } = await axios.put(`${API_URL}/api/event/${id}`, formData);
                    // console.log(data);

                    setLoading(false)
                    if (data.status !== 200) {
                        toast.error(`❗ ${data.message}!`, {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                    } else {
                        toast.success("✔️ Submision successful !", {
                            position: "top-right",
                            autoClose: 2500,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                        });
                        navigate("/admin/view/view_events");
                    }


                }, 2500);
            } catch (error) {
                console.error("Error submitting HMO data:", error.message);
            }
        }
    };

    const handleDescriptionChange = (data, descriptionKey) => {
        setFormData({
            ...formData,
            [descriptionKey]: data,
        });
    };
    console.log("fofm",formData);
    
    return (
        <Fragment>
            <AdminPageTitle activePage="Edit Events" pageName="Events Management" />

            <div className="d-flex col-xl-10 row-lg-12" >
                <div className="card" >
                    <div className="card-header">
                        <h4 className="card-title">Edit Events</h4>
                    </div>

                    <div className="card-body">
                        <div className="basic-form">

                            <form onSubmit={handleSubmit}>


                                <div className="mb-3 row">

                                    <label htmlFor="image" className="col-sm-3 col-form-label">Upload Image</label>
                                    <div className="col-sm-9">
                                        <input
                                            type="file"
                                            id="image"
                                            name="image"
                                            accept=".jpeg, .png, jpg"
                                            className="form-control"
                                            onChange={handleFileChange}
                                            ref={fileInputRef}
                                        />
                                        {imageError && <div style={{ color: 'red', marginTop: "5px" }}>{imageError}</div>}
                                    </div>
                                </div>
                                <div className="mb-3 justify-content-center">
                                    <label htmlFor="image" className="col-sm-3 col-form-label">Imag preview</label>
                                    <img className="ml-4" style={{ objectFit: "cover", border: "5px solid gray", padding: "5px" }} src={image.event_url ? image.event_url : `${API_URL}/public/event/event_Logo/${formData.logo_url}`} alt="" height={250} width={"95%"} />

                                </div>
                                <div className="row">
                                    <div className="col-xl-12 col-xxl-12">
                                        <div className="card">
                                            <div className="card-header">
                                                <h4 className="card-title">Event Description</h4>
                                            </div>
                                            <div className="card-body custom-ekeditor">
                                                <CKEditor
                                                    editor={ClassicEditor}
                                                    data={formData.description}
                                                    onChange={(event, editor) => {
                                                        const data = editor.getData();
                                                        handleDescriptionChange(data, 'description');
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="mb-3 row">
                                    <div className="col-sm-10">
                                        {loading ? <button type="loading" className="btn btn-primary" disabled>
                                            Submitting
                                        </button> :
                                            <button type="submit" className="btn btn-primary">
                                                Submit
                                            </button>}
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>


            </div>
            <ToastContainer />
        </Fragment>
    );
};

export default EditEvents;
