import React, { Fragment, useEffect, useRef, useState } from "react";
// import styles
import 'lightgallery/css/lightgallery.css';
import 'lightgallery/css/lg-zoom.css';
import 'lightgallery/css/lg-thumbnail.css';
import swal from 'sweetalert';
import AdminPageTitle from "../../AdminPageTitle/AdminPageTitle";
import { ExportToExcel } from "../../ReportM/ExportToExcel";
import axios from "axios";
import {
    Row,
    Col,
    Card,
    Table,
} from "react-bootstrap";
import { GlobalFilter } from '../../../components/table/FilteringTable/GlobalFilter';
import { Link, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import avatar3 from "../../../../images/1.jpg";
import { API_URL } from "../../../../apiconfig";
const filterStyle = { width: "fit-content", marginLeft: "8px", marginTop: "10px" }

const ViewPlan = () => {
    const navigate = useNavigate();
    // let sort = 10;
    const activePag = useRef(0);
    let paggination = [];
    let jobData = [];
    const [formData, setFormData] = useState({
        policy_name: '',
        hmo: '',
        plan_type: '',
        max_duration: '',
        monthly_cost: '',
        yearly_cost: '',
        deductible: '',
        coinsurance: '',
    });
    const [sort, setSort] = useState(10)
    const [exportExcelData, setExportExcelData] = useState([])
    const [hospitalData, setHospitalData] = useState([])
    const [hmo, setHMOs] = useState([]);
    const handleDelete = async (id) => {
        swal({
            title: "Are you sure?",
            text:
                "Once deleted, you will not be able to recover this record!",
            icon: "warning",
            buttons: true,
            dangerMode: true,
        }).then(async (willDelete) => {
            if (willDelete) {
                try {
                    const response = await axios.delete(`${API_URL}/api/Planpolicy_mapping_master/delete/${id}`);
                    getHospitals()
                    return response.data;
                } catch (error) {
                    console.error('Error message:', error.message);
                    throw error;
                }
            }
        })
    }
    const getHospitals = async () => {
        let { data } = await axios.get(`${API_URL}/api/Planpolicy_mapping_master`)
        setHospitalData(data && data.data.length && data.data)
    }
    const getExcelData = async () => {
        try {
            const { data } = await axios.get(`${API_URL}/api/Planpolicy_mapping_master/findAllexcel`)
            setExportExcelData(data && data.data.length && data.data)
        } catch (error) {
            console.log(error);

        }

    }
    const handleStatus = async (id, status) => {
        try {
            const { data } = await axios.patch(`${API_URL}/api/Planpolicy_mapping_master/${status}/${id}`)
            if (data.status !== 200) {
                toast.error(`${data.message}`)
                getHospitals()
            } else {
                toast.success(`${data.message} changed status`)
                getHospitals()
            }
        } catch (error) {
            toast.success(`${error.message}`)
        }
    }
    const getHmoListFilter = async () => {
        try {
            const { data } = await axios.get(`${API_URL}/api/hmo`);
            setHMOs(data.data);
        } catch (error) {
            toast.error("Failed to fetch data");
        }
    };
    useEffect(async () => {
        getHospitals()
        getExcelData()
        getHmoListFilter()
    }, [])
    const ExportExcelData = () => {
        const data = hospitalData && hospitalData.filter((el) => {

        })
    }
    paggination = Array(Math.ceil(hospitalData && hospitalData.length / sort))
        .fill()
        .map((_, i) => i + 1);
    jobData = hospitalData && hospitalData.slice(
        activePag.current * sort,
        (activePag.current + 1) * sort
    )
    const onClick = (i) => {
        activePag.current = i;

        jobData = hospitalData && hospitalData.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
        );
        /* setdemo(
          data.profileTable.data.slice(
            activePag.current * sort,
            (activePag.current + 1) * sort
          )
        ); */
    };
    const handleSortChange = (e) => {
        setSort(e.target.value)
    }
    const handleHospitalName = async (e) => {
        if (e && e.target.value === 'reset') {
            getHospitals()
        }
        else if (e.target.value != "" || e.target.value != null || e.target.value != undefined) {

            try {
                const { data } = await axios.post(`${API_URL}/api/Planpolicy_mapping_master/AscDESC`, { sort: e.target.value, "id": "" })
                setHospitalData(data && data.data.length && data.data)
            } catch (error) {
                toast.error(`${error}`)
            }
        } else {
            getHospitals()
        }
        // console.log("handleHospitalName", e.target.value)
    }
    const handleHmoName = async (e) => {
        if (e && e.target.value === 'reset') {
            getHospitals()
        }
        else if (e.target.value != "" || e.target.value != null || e.target.value != undefined) {

            try {
                const { data } = await axios.post(`${API_URL}/api/Planpolicy_mapping_master/AscDESC`, { sort: "", id: e.target.value })
                setHospitalData(data && data.data.length && data.data)
                if (data && data.data.length === 0) {
                    jobData = []
                    setHospitalData([])
                }
            } catch (error) {
                toast.error(`${error}`)
            }
        } else {
            getHospitals()
        }
        console.log("handleHospitalName", e.target.value)
    }
    console.log("Hospital Data:", hospitalData);
    console.log("Job Data:", jobData);
    return (
        <Fragment>
            <AdminPageTitle activePage="Plan Management" pageName="View Plans" />


            <Col lg={12}>
                <Card>
                    <Card.Header>
                        <Card.Title>Plan Details</Card.Title>
                    </Card.Header>
                    <div className="row">
                        <select
                            //   defaultValue={formData.type_of_hospital}
                            className="form-control form-control-sm"
                            name="type_of_hospital"
                            // value={formData.type_of_hospital}
                            onChange={(e) => handleSortChange(e)}
                            style={{ width: "fit-content", marginLeft: "25px", marginTop: "10px" }}
                            required
                        >
                            <option value={10}>Select Entry per page</option>
                            <option value={5}>5</option>
                            <option value={10}>10</option>
                            <option value={15}>15</option>

                        </select>
                        <select
                            className="form-control form-control-sm"
                            onChange={handleHospitalName}
                            style={filterStyle}
                        >
                            <option value={"reset"}>Sort by Name</option>
                            <option value={"asc"}>A-Z</option>
                            <option value={"DESC"}>Z-A</option>
                        </select>
                        <select
                            className="form-control form-control-sm"
                            onChange={handleHmoName}
                            style={filterStyle}
                        >
                            <option value={"reset"}>Sort by HMO</option>
                            {hmo && hmo.length > 0 && hmo.map((el, i) => {
                                return <option key={i} value={el.id}>{el.company_name}</option>
                            })}

                        </select>
                        <div style={{ width: "fit-content", marginLeft: "20%", marginTop: "10px" }}>
                            <ExportToExcel apiData={exportExcelData && exportExcelData} fileName={"All-plans"} />
                        </div>
                    </div>
                    <Card.Body style={{ padding: "10px" }}>
                        <Table responsive>
                            <thead>
                                <tr>
                                    <td>
                                        <strong> Policy Name</strong>

                                    </td>
                                    <td>
                                        <strong> HMO Name</strong>

                                    </td>
                                    <td>
                                        <strong> Plan</strong>

                                    </td>
                                    <td>
                                        <strong>  Max Duration</strong>
                                    </td>
                                    <td>
                                        <strong> Monthly Cost</strong>

                                    </td>
                                    <td>
                                        <strong>Yearly Cost</strong>

                                    </td>
                                    <td>
                                        <strong>Coinsurance</strong>

                                    </td>
                                    <td>
                                        <strong>Status</strong>
                                    </td>
                                    <td>
                                        <strong>Action</strong>

                                    </td>
                                </tr>
                            </thead>

                            <tbody>
                                {/* {userData && userData.length > 0 && jobData.map((user, i) => { */}
                                {hospitalData && jobData.map((el) => {
                                    return (
                                        <tr key={el.id}>
                                            <td>{el.policy_name}</td>
                                            <td>{el.hmo}</td>
                                            <td>{el.plan_type}</td>
                                            <td>{el.max_duration}</td>
                                            <td>₦{el.monthly_cost}</td>
                                            <td>₦{el.yearly_cost}</td>
                                            <td>{el.coinsurance}</td>
                                            <td>
                                                <div>
                                                    {el.active === 1 ?
                                                        <button
                                                            onClick={() => handleStatus(el.id, "inactive")}
                                                            className="btn btn-danger shadow btn-sm sharp">Inactive</button>
                                                        :
                                                        <button
                                                            onClick={() => handleStatus(el.id, "active")}
                                                            className="btn btn-primary shadow btn-sm sharp me-1">Active</button>
                                                    }
                                                </div>
                                            </td>
                                            <td>
                                                <div className="d-flex">
                                                    <Link
                                                        // href={`/add-hmo/${user.id}`}
                                                        to={`/admin/view/edit_plan/${el.id}`}
                                                        className="btn btn-primary shadow btn-xs sharp me-1"
                                                    >
                                                        <i className="fas fa-pen"></i>
                                                    </Link>
                                                    <Link
                                                        onClick={() => handleDelete(el.id)}
                                                        className="btn btn-danger shadow btn-xs sharp"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </Link>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                                {jobData.length === 0 && <tr>
                                        <td colSpan="8" className="text-center">
                                            No data available
                                        </td>
                                    </tr>}
                            </tbody>
                        </Table>

                        <div id="example_wrapper" className="dataTables_wrapper"><div className="d-sm-flex text-center justify-content-between align-items-center mt-3">
                            <div className="dataTables_info">
                                Showing {activePag.current * sort + 1} to{" "}
                                {hospitalData.length > (activePag.current + 1) * sort
                                    ? (activePag.current + 1) * sort
                                    : hospitalData.length}{" "}
                                of {hospitalData.length} entries
                            </div>
                            <div
                                className="dataTables_paginate paging_simple_numbers"
                                id="example5_paginate"
                            >
                                <Link
                                    className="paginate_button previous disabled"
                                    to="/admin/view/view_plan"
                                    onClick={() =>
                                        activePag.current > 0 && onClick(activePag.current - 1)
                                    }
                                >
                                    <i className="fa fa-angle-double-left" aria-hidden="true"></i>
                                </Link>
                                <span>
                                    {paggination.map((number, i) => (
                                        <Link
                                            key={i}
                                            to="/admin/view/view_plan"
                                            className={`paginate_button  ${activePag.current === i ? "current" : ""
                                                } `}
                                            onClick={() => onClick(i)}
                                        >
                                            {number}
                                        </Link>
                                    ))}
                                </span>
                                <Link
                                    className="paginate_button next"
                                    to="/admin/view/view_plan"
                                    onClick={() =>
                                        activePag.current + 1 < paggination.length &&
                                        onClick(activePag.current + 1)
                                    }
                                >
                                    <i className="fa fa-angle-double-right" aria-hidden="true"></i>
                                </Link>
                            </div>
                        </div>

                        </div>
                    </Card.Body>
                </Card>
                <ToastContainer />
            </Col>
            <ToastContainer />
        </Fragment>
    );
};

export default ViewPlan;
